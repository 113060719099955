import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import VideoPlayer from "./VideoPlayer";
// import ExpandableTags from "./ExpandableTags";

const playerBanner = [
    { src: "b_1xbet.png", to: "https://1xbet.com" },
    {
        src: "b_alphabet.gif",
        to: "https://161apb.com/affiliates/?btag=1769467",
    },
    { src: "b_indie.gif", to: "https://ind-domain03.com//?ref=1919" },
    {
        src: "b_baro.gif",
        to: "https://baro.bet/sports/prematch?referral=1818",
    },
    { src: "b_neobet.gif", to: "http://nbtpass.com" },
];

const Video = ({ video }) => {
    const [selectedBanners, setSelectedBanners] = useState([]);

    useEffect(() => {
        // 배너 배열을 복사합니다.
        const banners = [...playerBanner];

        // 선택할 배너의 개수
        const numOfBannersToShow = 3;

        // 무작위로 배너를 선택합니다.
        const selected = [];
        for (let i = 0; i < numOfBannersToShow; i++) {
            const randomIndex = Math.floor(Math.random() * banners.length);
            selected.push(banners[randomIndex]);
            banners.splice(randomIndex, 1); // 선택된 배너를 배열에서 제거합니다.
        }

        setSelectedBanners(selected);
    }, []); // 빈 배열을 전달하여 컴포넌트가 마운트될 때 한 번만 실행되게 합니다.

    return (
        <div className="mt-2 md:mt-4 space-y-2">
            <div className="relative w-full md:h-[500px] flex items-center justify-center space-x-1">
                <div className="flex-grow h-auto md:h-[500px]">
                    <VideoPlayer video={video} />
                </div>
                <div className="hidden md:block min-w-[492px] h-[500px] w-[492px] space-y-1 pb-2">
                    {selectedBanners.map((banner, index) => (
                        <div className="h-1/3" key={index}>
                            <Link to={banner.to} target="_blank">
                                <img
                                    src={"/banner/" + banner.src}
                                    className="w-full object-cover"
                                    alt={`Banner ${index + 1}`}
                                />
                            </Link>
                        </div>
                    ))}
                </div>
            </div>

            <div className="px-2 md:px-0 text-xs md:text-sm space-y-2">
                <div
                    className="text-xl md:text-2xl leading-6 md:leading-8 font-medium"
                    dangerouslySetInnerHTML={{ __html: video.title }}
                />
                <div className="text-gray-400">
                    {video.uploader} · 조회수 {video.views}회
                </div>

                {/* <ExpandableTags tags={video.tags} /> */}
            </div>

            <div className="md:hidden block w-full pt-5 space-y-1">
                {selectedBanners.map((banner, index) => (
                    <div key={index}>
                        <Link to={banner.to} target="_blank">
                            <img
                                src={"/banner/" + banner.src}
                                className="h-full w-full md:w-auto object-cover"
                                alt={`Banner ${index + 1}`}
                            />
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Video;
