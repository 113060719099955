import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { FaTelegramPlane } from "react-icons/fa";

import { useLocation, Link } from "react-router-dom";

import HamburgerMenu from "./HamburgerMenu";
import SearchPopup from "./SearchPopup";

const Header = () => {
    const [keyword, setKeyword] = useState("");
    const [isOpen, setIsOpen] = useState(false);

    const navigate = useNavigate();
    const handleInputChange = (e) => {
        setKeyword(e.target.value);
    };

    const handleSearchClick = () => {
        if (!keyword.trim()) return;

        const path = "/?k=" + keyword;
        navigate(path);
        setKeyword("");
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        handleSearchClick();
    };

    const location = useLocation();
    const getLinkClassName = (basePath) => {
        const currentPath = location.pathname + location.search;

        // news 메뉴는 기본 path가 / 이지만, 특정 페이지로 이동하면 /new/xxx 가 됨
        const isActive =
            currentPath === "/" && basePath === "/new"
                ? true
                : currentPath.startsWith(basePath);

        return isActive
            ? "transition duration-300 ease-in-out border-b-2 pb-1 border-current"
            : "pb-2";
    };

    const getPreviousMonth = () => {
        const currentDate = new Date();
        currentDate.setMonth(currentDate.getMonth() - 1);
        const year = currentDate.getFullYear();
        let month = currentDate.getMonth() + 1;
        month = month < 10 ? `0${month}` : month;

        return `${year}-${month}`;
    };

    const categories = [
        { path: "/lang/korean", label: "Korean" },
        { path: "/lang/japanese", label: "Japanese" },
        { path: "/lang/chinese", label: "Chinese" },
        { path: "/lang/thai", label: "Thai" },
        { path: "/lang/viet_nam", label: "Vietnam" },
    ];

    const closeMenu = () => {
        setIsOpen(false);
    };

    const handleMenuClick = (event) => {
        event.stopPropagation();
        setIsOpen(!isOpen);
    };

    // 바깥쪽 클릭 감지를 위한 이벤트 리스너
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (
                isOpen &&
                !event.target.closest(".menu-container") &&
                !event.target.closest(".country-button")
            ) {
                closeMenu();
            }
        };

        document.addEventListener("mousedown", handleOutsideClick);

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, [isOpen]);

    return (
        <header className="bg-[#9347FF] text-black">
            <div className="hidden md:flex items-center max-w-screen-2xl mx-auto px-2 md:px-12 h-[94px] relative">
                <div className="flex flex-row basis-1/6 space-x-2 items-center text-lg font-light">
                    <HamburgerMenu />
                    <div className="text-white pl-2 pt-2">Category</div>
                </div>
                <div className="hidden lg:flex basis-3/6 justify-center pt-4">
                    <div className="flex space-x-10 text-lg relative">
                        <Link to="/" className={getLinkClassName("/new")}>
                            추천
                        </Link>
                        <Link
                            to={`/best/${getPreviousMonth()}`}
                            className={getLinkClassName(
                                `/best/${getPreviousMonth()}`
                            )}
                        >
                            베스트
                        </Link>
                        <button
                            className="country-button pb-2"
                            onClick={handleMenuClick}
                        >
                            <div className="flex flex-row items-center">
                                <span>국가/언어</span>
                                <span className="wider-icon ml-2">▾</span>
                            </div>
                            <style jsx>{`
                                .wider-icon {
                                    display: inline-block;
                                    transform: scaleX(2); /* 가로로 2배 확대 */
                                }
                            `}</style>
                        </button>
                        <a
                            href="https://t.me/megaporn9"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <div className="flex flex-row items-center gap-0.5">
                                <FaTelegramPlane />
                                최신주소
                            </div>
                        </a>
                    </div>
                </div>
                <div className="flex-grow flex basis-1/6 gap-10 items-center">
                    <Link to="/">
                        <img
                            src="/assets/megaporn.png"
                            alt="megaporn"
                            className="w-[118px] h-[78px]"
                        />
                    </Link>
                </div>
                <div className="flex-grow flex basis-1/3 gap-10 items-center">
                    {/* <Link to="/">
                        <img
                            src="/assets/megaporn.png"
                            alt="megaporn"
                            className="w-[118px] h-[78px]"
                        />
                    </Link> */}
                    <Link to="https://go.megatoon.me">
                        <img
                            src="/assets/megatoon.png"
                            alt="megatoon"
                            className="w-[123.39px] h-[17.68px] mt-2"
                        />
                    </Link>
                    {/* <Link onClick={() => alert("서비스 준비중입니다.")}>
                        <img
                            src="/assets/megatv.png"
                            alt="megatv"
                            className="w-[80px] h-[24px] mt-2"
                        />
                    </Link> */}
                </div>
                <div className="flex-grow flex items-center justify-end basis-1/3">
                    <div className="mt-2 flex rounded-md shadow-sm w-3/4">
                        <form
                            className="relative flex flex-grow items-stretch focus-within:z-10 border-white focus:border-[#ea3776] hover:border-[#ea3776] border-2 rounded-md"
                            onSubmit={handleSubmit}
                        >
                            <input
                                value={keyword}
                                onChange={handleInputChange}
                                className="block w-full text-sm rounded-none rounded-l-md border-none py-1 pl-3 bg-transparent text-white placeholder:text-white placeholder:font-extralight leading-6 focus:outline-none focus:ring-[#ea3776] hover:ring-[#ea3776]"
                                placeholder="검색"
                            />
                            <button
                                onClick={handleSearchClick}
                                type="button"
                                className="relative inline-flex items-center rounded-r px-2.5 py-1 hover:bg-[#ea3776] focus:bg-[#ea3776] hover:ring-[#ea3776] focus:ring-[#ea3776]"
                            >
                                <MagnifyingGlassIcon
                                    className="-ml-0.5 h-6 w-6 text-white"
                                    aria-hidden="true"
                                />
                            </button>
                        </form>
                    </div>
                </div>
            </div>

            <div className="flex md:hidden items-center max-w-screen-2xl mx-auto px-4 lg:px-12 h-[90px] relative">
                <div className="flex flex-row basis-1/3 space-x-2 items-center text-lg font-light">
                    <HamburgerMenu />
                </div>
                <div className="flex basis-1/3 justify-center">
                    <Link to="/">
                        <img
                            src="/assets/megaporn.png"
                            alt="logo"
                            className="w-[107px] h-[71px]"
                        />
                    </Link>
                </div>
                <div className="flex-grow flex items-center justify-end basis-1/3">
                    <SearchPopup />
                </div>
            </div>

            <div className="flex lg:hidden w-full justify-center items-center space-x-4 mb-1 text-sm">
                <Link to="/" className={getLinkClassName("/new")}>
                    추천
                </Link>
                <Link
                    to={`/best/${getPreviousMonth()}`}
                    className={getLinkClassName(`/best/${getPreviousMonth()}`)}
                >
                    베스트
                </Link>
                <button
                    className="country-button pb-2"
                    onClick={handleMenuClick}
                >
                    <div className="flex flex-row items-center">
                        <span>국가/언어</span>
                        <span className="wider-icon ml-1">▾</span>
                    </div>
                </button>
                <a
                    href="https://t.me/megaporn9"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="-mt-2"
                >
                    <div className="flex flex-row items-center gap-0.5">
                        <FaTelegramPlane />
                        최신주소
                    </div>
                </a>
                <Link to="https://go.megatoon.me" className="mb-2">
                    <img
                        src="/assets/megatoon.png"
                        alt="logo"
                        className="w-[98px] h-[14px]"
                    />
                </Link>
                {/* <Link to="/" className="mb-2">
                    <img
                        src="/assets/megatv.png"
                        alt="logo"
                        className="w-[63px] h-[19px]"
                    />
                </Link> */}
            </div>

            {isOpen && (
                <div
                    className="menu-container absolute bg-[#9347FFbb] text-white mt-2 md:mt-3 ml-8 md:ml-0 p-3 shadow-2xl z-50 w-[130px]"
                    style={{
                        top: "170px",
                        left: "35%",
                        transform: "translateX(-50%)",
                    }}
                    onClick={handleMenuClick}
                >
                    <div className="grid gap-5 text-sm">
                        {categories.map((category, index) => (
                            <Link to={category.path} key={index}>
                                {category.label}
                            </Link>
                        ))}
                    </div>
                </div>
            )}
        </header>
    );
};

export default Header;
