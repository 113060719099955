import React from "react";
import { Link } from "react-router-dom";

const footerBanner = [
    { src: "b_1xbet.png", to: "https://1xbet.com" },
    {
        src: "b_alphabet.gif",
        to: "https://161apb.com/affiliates/?btag=1769467",
    },
    { src: "b_indie.gif", to: "https://ind-domain03.com//?ref=1919" },

    {
        src: "b_baro.gif",
        to: "https://baro.bet/sports/prematch?referral=1818",
    },
    { src: "b_neobet.gif", to: "http://nbtpass.com" },
];

// const footerLink = [
//     { title: "여기여", url: "https://www.ygy01.com/" },
//     { title: "주소위키", url: "https://xn--9l4b23hfvbc3n.com/" },
//     { title: "링크사랑", url: "https://linklove47.com/" },
//     { title: "링크판", url: "https://linkpan64.com/" },
//     { title: "주소야", url: "https://jusoya.com/" },

//     { title: "MYLINK", url: "https://amylink.net/" },
//     { title: "링크썸", url: "https://linksome.net/" },
//     { title: "링크조아", url: "https://www.lkzoa8.com/" },
//     { title: "올링", url: "https://alling26.com/" },
//     { title: "야주소", url: "https://yajuso.com/" },
// ];

function getRandomBanners(banners, count) {
    const shuffled = banners.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, count);
}

function FooterAds() {
    const randomBanners = getRandomBanners(
        [...footerBanner, ...footerBanner],
        6
    ); // footerBanner를 두 번 복사하여 총 8개 중에서 6개를 선택

    return (
        <div className="mt-10 md:mt-10 md:mb-10">
            <div className="flex items-center max-w-screen-2xl mx-auto px-0 text-white">
                <div className="w-full">
                    <div className="grid grid-rows-6 md:grid-rows-2 grid-flow-col gap-1 w-full text-white">
                        {randomBanners.map((banner, index) => (
                            <div key={index}>
                                <Link to={banner.to} target="_blank">
                                    <img
                                        src={`/banner/${banner.src}`}
                                        className="w-full object-cover"
                                        alt={`banner-${index}`}
                                    />
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* <div className="flex items-center max-w-screen-2xl mx-auto px-0 py-3 mt-10 text-white">
                <div className="w-full">
                    <div className="grid grid-cols-2 md:grid-cols-5 grid-flow-row gap-1 w-full text-black">
                        {footerLink.map((banner, index) => (
                            <Link to={banner.url} target="_blank" key={index}>
                                <div
                                    key={index}
                                    className="flex h-16 border items-center justify-center text-xl text-white bg-[#777777]"
                                >
                                    {banner.title}
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
            </div> */}

            <div className="flex text-xs w-full justify-center text-gray-900">
                v05081200
            </div>
        </div>
    );
}

export default FooterAds;
