import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";

const SearchPopup = () => {
    const [isOpen, setIsOpen] = useState(false);

    const closeMenu = () => {
        setIsOpen(false);
    };

    const handleMenuClick = (event) => {
        event.stopPropagation();
        if (event.target.closest("a")) {
            closeMenu();
        }
    };

    // 바깥쪽 클릭 감지를 위한 이벤트 리스너
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (isOpen && !event.target.closest(".menu-container")) {
                closeMenu();
            }
        };

        document.addEventListener("mousedown", handleOutsideClick);

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, [isOpen]);

    const [keyword, setKeyword] = useState("");

    const navigate = useNavigate();
    const handleInputChange = (e) => {
        setKeyword(e.target.value);
    };

    const handleSearchClick = () => {
        if (!keyword.trim()) return;

        const path = "/?k=" + keyword;
        navigate(path);
        setKeyword("");
        setIsOpen(false);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        handleSearchClick();
    };

    return (
        <>
            <div className="relative">
                <button className="flex flex-col justify-around items-end w-8 h-8 bg-transparent border-none cursor-pointer -ml-1 p-0 z-10">
                    <MagnifyingGlassIcon
                        aria-hidden="true"
                        className={`h-6 w-6 text-white ${
                            isOpen ? "hidden" : ""
                        }`}
                        onClick={() => setIsOpen(true)}
                    />

                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className={` ${!isOpen ? "hidden" : ""}`}
                        onClick={() => setIsOpen(false)}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18 18 6M6 6l12 12"
                        />
                    </svg>
                </button>
            </div>

            <div
                className={`menu-container absolute bg-gray-200 top-14 -left-2 md:left-10 right-0 md:right-12 p-3 rounded-none md:rounded-md shadow-2xl z-10 ${
                    isOpen ? "block" : "hidden"
                }`}
                onClick={handleMenuClick}
            >
                <div className="pl-2 text-xs md:text-sm">
                    <form
                        className="relative flex flex-grow items-stretch focus-within:z-10 border-gray-500 focus:border-[#ea3776] hover:border-[#ea3776] border"
                        onSubmit={handleSubmit}
                    >
                        <input
                            value={keyword}
                            onChange={handleInputChange}
                            className="block w-full rounded-none rounded-l-md border-none py-1.5 pl-3 text-gray-900 placeholder:text-gray-400 leading-6 focus:outline-none focus:ring-[#ea3776] hover:ring-[#ea3776]"
                            placeholder="Search videos"
                        />
                        <button
                            onClick={handleSearchClick}
                            type="button"
                            className="relative inline-flex items-center px-2.5 py-1 bg-[#777777] hover:bg-[#ea3776] focus:bg-[#ea3776] hover:ring-[#ea3776] focus:ring-[#ea3776]"
                        >
                            <MagnifyingGlassIcon
                                className="-ml-0.5 h-6 w-6 text-white"
                                aria-hidden="true"
                            />
                        </button>
                    </form>
                </div>
            </div>
        </>
    );
};

export default SearchPopup;
