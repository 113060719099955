import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import Thumbnails from "./view/Thumbnails";
import Video from "./view/Video";
// import Loading from "./view/Loading";
import Pagination from "./view/Pagenation";
import PageInfo from "./view/PageInfo";
import FooterAds from "./view/FooterAds";

const API_SERVER = process.env.REACT_APP_API_URL;

const Page = () => {
    const [info, setInfo] = useState({});
    const [video, setVideo] = useState({});
    const [thumbnails, setThumbnails] = useState([]);
    const [pages, setPages] = useState([]);
    const [ads, setAds] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");

    const location = useLocation();
    const path = location.pathname + location.search;

    useEffect(() => {
        window.scrollTo(0, 0);

        const fetchData = async () => {
            setIsLoading(true);
            setError("");
            const startTime = Date.now();

            try {
                const { data } = await axios.get(API_SERVER + path);
                setThumbnails(data.thumbnails || []);
                setPages(data.pages || []);
                setInfo(data.info || {});
                setAds(data.ads || []);
                setVideo(data.video || {});
            } catch (error) {
                console.error("Error fetching data:", error);
                setError("Videos not found");
            } finally {
                const endTime = Date.now();
                const timeElapsed = endTime - startTime;
                const delay = Math.max(2000 - timeElapsed, 0);

                setTimeout(() => {
                    setIsLoading(false);
                }, delay);

                window.scrollTo(0, 0);
            }
        };

        fetchData();
    }, [path]);

    return (
        <>
            {/* <Loading /> */}
            {isLoading ? (
                <div className="m-96 h-1/2 text-2xl flex items-center justify-center">
                    <div className="text-center">
                        <img
                            src="/assets/loading.webp"
                            className="w-28 mx-auto filter-invert"
                            alt="loading..."
                        />
                    </div>
                    <style jsx>{`
                        .filter-invert {
                            filter: invert(100%) sepia(0%) saturate(0%)
                                hue-rotate(0deg) brightness(100%) contrast(100%);
                        }
                    `}</style>
                </div>
            ) : error ? (
                <div className="flex justify-center items-center text-2xl text-[#ea3776] font-semibold text-center h-screen">
                    {error}
                </div>
            ) : (
                <>
                    {info && Object.keys(info).length > 0 && (
                        <div className="top-0 py-3 z-3">
                            <PageInfo info={info} />
                        </div>
                    )}

                    {video && Object.keys(video).length !== 0 ? (
                        <>
                            <Video video={video} />

                            <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 px-2 md:px-0 mt-8 md:mt-6 gap-x-1 md:gap-x-1 lg:gap-x-2 gap-y-8 md:gap-y-5 lg:gap-y-6">
                                <Thumbnails
                                    type="relatedList"
                                    thumbnails={thumbnails}
                                    ads={ads}
                                />
                            </div>
                        </>
                    ) : (
                        <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-5 mt-0 md:mt-3 md:gap-x-1 lg:gap-x-2 gap-y-8 md:gap-y-5 lg:gap-y-6">
                            <Thumbnails
                                type="mainList"
                                thumbnails={thumbnails}
                                ads={ads}
                            />
                        </div>
                    )}

                    <FooterAds />

                    <Pagination pages={pages} />
                </>
            )}
        </>
    );
};

export default Page;
