import React, { useEffect } from "react";
import {
    HashRouter as Router,
    Route,
    Routes,
    useLocation,
} from "react-router-dom";
import { Helmet } from "react-helmet";

import Page from "./Page";
import Footer from "./view/Footer";
import Header from "./view/Header";
import "./App.css";
import HeaderAds from "./view/HeaderAds";

// Scroll to top on every route change
const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]); // 경로가 변경될 때마다 스크롤을 최상단으로 이동

    return null;
};

function App() {
    return (
        <Router>
            <Helmet>
                <title>메가폰 - 대한민국 No.1 무료 야동 사이트</title>
                <meta
                    name="description"
                    content="아시아부터 유럽까지 세계 각구의 흥미로운 포르노와 야동이 매일 업데이트 됩니다."
                />
            </Helmet>

            <ScrollToTop />
            <HeaderAds />
            <Header />

            <div className="max-w-screen-2xl mx-auto w-full px-0 md:px-12">
                <Routes>
                    <Route path="*" element={<Page />} />
                </Routes>
            </div>

            <Footer />
        </Router>
    );
}

export default App;
