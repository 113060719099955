import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const categories = [
    { path: "/?k=3D", label: "3D" },
    { path: "/?k=AI", label: "AI" },

    { path: "/c/Amateur-65", label: "Amateur" },
    { path: "/c/Anal-12", label: "Anal" },
    { path: "/c/Arab-159", label: "Arab" },
    { path: "/c/Asian_Woman-32", label: "Asian" },
    { path: "/c/ASMR-229", label: "ASMR" },
    { path: "/c/Ass-14", label: "Ass" },
    { path: "/c/bbw-51", label: "BBW" },
    { path: "/c/Bi_Sexual-62", label: "Bi" },
    { path: "/c/Big_Ass-24", label: "Big Ass" },
    { path: "/c/Big_Cock-34", label: "Big Cock" },
    { path: "/c/Big_Tits-23", label: "Big Tits" },
    { path: "/c/Black_Woman-30", label: "Black" },
    { path: "/c/Blonde-20", label: "Blonde" },
    { path: "/c/Blowjob-15", label: "Blowjob" },
    { path: "/c/Brunette-25", label: "Brunette" },
    { path: "/c/Cam_Porn-58", label: "Cam Porn" },

    { path: "/?k=China", label: "China" },

    { path: "/c/Creampie-40", label: "Creampie" },
    { path: "/c/Cuckold-237", label: "Cuckold/Hotwife" },
    { path: "/c/Cumshot-18", label: "Cumshot" },

    { path: "/?k=Drake", label: "Drake" },

    { path: "/c/Femdom-235", label: "Femdom" },
    { path: "/c/Fisting-165", label: "Fisting" },
    { path: "/c/Fucked_Up_Family-81", label: "Fucked Up Family" },

    { path: "/?k=Game", label: "Game" },

    { path: "/c/Gangbang-69", label: "Gangbang" },
    { path: "/c/Gapes-167", label: "Gapes" },

    { path: "/gay", label: "Gay" },

    { path: "/?k=Hardcore", label: "Hardcore" },
    { path: "/?k=Hentai game", label: "Hentai game" },

    { path: "/c/Indian-89", label: "Indian" },
    { path: "/c/Interracial-27", label: "Interracial" },

    { path: "/?k=Japanese massage", label: "Japanese massage" },
    { path: "/?k=Japanese milf", label: "Japanese milf" },
    { path: "/?k=Japanese wife", label: "Japanese wife" },
    { path: "/?k=Korea", label: "Korea" },
    { path: "/?k=Korean bj", label: "Korean bj" },
    { path: "/?k=Korean movie", label: "Korean movie" },

    { path: "/c/Latina-16", label: "Latina" },
    { path: "/c/Lesbian-26", label: "Lesbian" },
    { path: "/c/Lingerie-83", label: "Lingerie" },

    { path: "/?k=Massage", label: "Massage" },

    { path: "/c/Mature-38", label: "Mature" },
    { path: "/c/Milf-19", label: "Milf" },

    { path: "/?k=Movie", label: "Movie" },

    { path: "/c/Oiled-22", label: "Oiled" },

    { path: "/?k=Orgasm", label: "Orgasm" },
    { path: "/?k=Real", label: "Real" },

    { path: "/c/Redhead-31", label: "Redhead" },

    { path: "/shemale", label: "Shemale" },

    { path: "/c/Solo_and_Masturbation-33", label: "Solo" },
    { path: "/c/Squirting-56", label: "Squirting" },
    { path: "/c/Stockings-28", label: "Stockings" },

    { path: "/?k=Teacher", label: "Teacher" },

    { path: "/c/Teen-13", label: "Teen" },

    { path: "/?k=Thai", label: "Thai" },
];

const HamburgerMenu = () => {
    const [isOpen, setIsOpen] = useState(false);

    const closeMenu = () => {
        setIsOpen(false);
    };

    const handleMenuClick = (event) => {
        event.stopPropagation();
        if (event.target.closest("a")) {
            closeMenu();
        }
    };

    // 바깥쪽 클릭 감지를 위한 이벤트 리스너
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (isOpen && !event.target.closest(".category-menu")) {
                closeMenu();
            }
        };

        document.addEventListener("mousedown", handleOutsideClick);

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, [isOpen]);

    return (
        <>
            <div className="relative">
                <button className="flex flex-col justify-around w-8 h-8 bg-transparent border-none text-white cursor-pointer -ml-1 p-0 z-50">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 20"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className={`${isOpen ? "hidden" : ""}`}
                        onClick={() => setIsOpen(true)}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                        />
                    </svg>

                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 20"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className={`${!isOpen ? "hidden" : ""}`}
                        onClick={() => setIsOpen(false)}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18 18 6M6 6l12 12"
                        />
                    </svg>
                </button>
            </div>

            <div
                className={`category-menu absolute bg-[#9347FF] text-white top-24 md:top-22 -left-2 md:left-10 right-0 md:right-12 p-3 rounded-none md:rounded-md shadow-2xl z-50 ${
                    isOpen ? "block" : "hidden"
                }`}
                onClick={handleMenuClick}
            >
                <div className="grid grid-cols-3 md:grid-cols-6 gap-2 md:gap-3 p-0 md:p-2 text-xs md:text-sm">
                    {categories.map((category, index) => (
                        <Link to={category.path} key={index}>
                            {category.label}
                        </Link>
                    ))}
                </div>
            </div>
        </>
    );
};

export default HamburgerMenu;
