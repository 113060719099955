import React, { useState, useEffect, useRef } from "react";
import Hls from "hls.js";

const VideoPlayer = ({ video }) => {
    const [videoData, setVideoData] = useState("");
    const [isPlaying, setIsPlaying] = useState(false);
    const videoRef = useRef(null);

    useEffect(() => {
        setVideoData(video);
    }, [video]);

    useEffect(() => {
        let hls;
        if (videoData) {
            if (Hls.isSupported()) {
                hls = new Hls();
                hls.loadSource(videoData.hls);
                hls.attachMedia(videoRef.current);
                hls.on(Hls.Events.MANIFEST_PARSED, function () {
                    // videoRef.current.play();
                });
            } else if (
                videoRef.current &&
                videoRef.current.canPlayType("application/vnd.apple.mpegurl")
            ) {
                videoRef.current.src = videoData.hls;
                videoRef.current.addEventListener(
                    "loadedmetadata",
                    function () {
                        // videoRef.current.play();
                    }
                );
            }
        }

        const handlePause = () => {
            setIsPlaying(false);
        };

        const handlePlay = () => {
            setIsPlaying(true);
        };

        const videoElement = videoRef.current;
        if (videoElement) {
            videoElement.addEventListener("pause", handlePause);
            videoElement.addEventListener("ended", handlePause);
            videoElement.addEventListener("play", handlePlay);
        }

        return () => {
            if (hls) {
                hls.destroy();
            }
            if (videoElement) {
                videoElement.removeEventListener("pause", handlePause);
                videoElement.removeEventListener("ended", handlePause);
                videoElement.removeEventListener("play", handlePlay);
            }
        };
    }, [videoData]);

    const handlePlayButtonClick = () => {
        setIsPlaying(true);
        if (videoRef.current) {
            videoRef.current.play();
        }
    };

    return (
        <>
            {videoData ? (
                <div className="relative w-full h-full flex justify-center items-center bg-black">
                    <video
                        ref={videoRef}
                        className="w-full h-full"
                        style={{ objectFit: "contain" }}
                        poster={videoData.thumbnail}
                        controls
                        disableRemotePlayback
                        playsInline
                    />
                    {!isPlaying && (
                        <button
                            className="absolute flex items-center justify-center text-white bg-gray-800 bg-opacity-75 p-3 w-20 h-20 rounded-full"
                            style={{ fontSize: "2.5rem" }}
                            onClick={handlePlayButtonClick}
                        >
                            ▶
                        </button>
                    )}
                </div>
            ) : (
                <div>Loading...</div>
            )}
        </>
    );
};

export default VideoPlayer;
