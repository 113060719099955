import React, { useRef, useState } from "react"; // useState와 useEffect를 추가해줍니다.
import { Link } from "react-router-dom";

const Thumbnails = ({ type, thumbnails, ads }) => {
    const [hoverIndex, setHoverIndex] = useState(null); // 새로운 상태 추가

    const videoRefs = useRef([]);

    const handleMouseOver = (index) => {
        setHoverIndex(index); // 마우스 오버된 항목의 인덱스를 설정
    };

    const handleMouseOut = () => {
        setHoverIndex(null); // 마우스가 벗어나면 null로 설정
    };

    // const randomViews = () => {
    //     // 2 ~ 6000 까지 랜덤 숫자 발생
    //     const randomNumber = Math.random() * (6000 - 2) + 2;

    //     // 1000 이하는 뒤에 k를 붙이고,
    //     // 1000 이상이면 나누기 1000 해서 M을 붙인다.
    //     // 숫자는 소수점 한자리까지 표시한다.
    //     if (randomNumber < 1000) {
    //         return `${randomNumber.toFixed(1)}k`;
    //     } else {
    //         return `${(randomNumber / 1000).toFixed(1)}M`;
    //     }
    // };

    return (
        <>
            {thumbnails.map((thumbnail, index) => (
                <div
                    className="group relative"
                    key={index}
                    onTouchStart={() => handleMouseOver(index)} // 터치 시작시 비디오 재생
                    onMouseOver={() => handleMouseOver(index)}
                    onMouseOut={() => handleMouseOut()}
                    // onTouchEnd={() => handleMouseOut(index)} // 터치 종료시 비디오 정지
                >
                    <div className="relative w-full overflow-hidden rounded-none md:rounded-md bg-gray-200">
                        <img
                            src={thumbnail.image}
                            alt={thumbnail.title}
                            className="h-full w-full object-cover object-center"
                        />

                        {hoverIndex === index && ( // 마우스 오버된 항목에만 비디오 태그를 렌더링
                            <Link to={thumbnail.href} className="h-full w-full">
                                <video
                                    src={thumbnail.preview}
                                    ref={(el) =>
                                        (videoRefs.current[index] = el)
                                    }
                                    className="absolute top-0 left-0 w-full h-full object-cover rounded-md hidden group-hover:block"
                                    muted
                                    loop
                                    autoPlay
                                    disableRemotePlayback
                                    playsInline
                                ></video>
                            </Link>
                        )}

                        <p className="absolute rounded px-1 py-0.5 right-1 bottom-1 text-[10px] text-white bg-black">
                            {thumbnail.duration}
                        </p>
                    </div>
                    <p
                        className={`${
                            type === "relatedList" ? "px-0" : "px-2"
                        } mt-2 text-base md:text-sm font-medium leading-5 md:leading-4 line-clamp-2`}
                    >
                        <Link to={thumbnail.href}>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: thumbnail.title,
                                }}
                            />
                        </Link>
                    </p>
                    <p
                        className={`${
                            type === "relatedList" ? "px-0" : "px-2"
                        } flex flex-row mt-2 md:mt-2 text-xs font-medium text-gray-400`}
                    >
                        <p>{thumbnail.uploader}&nbsp;</p>
                        {/* {`${
                            thumbnail.views === "-"
                                ? randomViews()
                                : thumbnail.views
                        } views`} */}
                        {`${
                            thumbnail.views === "-"
                                ? ""
                                : " · 조회수 " + thumbnail.views + "회"
                        }`}
                    </p>
                </div>
            ))}
        </>
    );
};

export default Thumbnails;
