import React from "react";

function Footer() {
    return (
        <footer className="bg-[#222222]">
            <div className="flex flex-col items-center md:items-start justify-center md:justify-between max-w-screen-2xl gap-0 md:gap-5 mx-auto px-5 md:px-12 py-8 space-y-5 md:space-y-0 font-thin text-white text-xs md:text-sm">
                <div>
                    <img
                        src="/assets/megaporn_white.png"
                        alt=""
                        className="w-[156px] h-[46px]"
                    />
                </div>
                <div
                    id="menu"
                    className="space-x-2 md:space-x-3 text-center md:text-left"
                >
                    <span>Terms</span>
                    <span>|</span>
                    <span>Privacy Policy</span>
                    <span>|</span>
                    <span>C/S</span>
                    <span>|</span>
                    <a
                        target="_blank"
                        href="https://t.me/weplus19"
                        rel="noreferrer"
                    >
                        partnership (telegram)
                    </a>
                </div>
                <div className="md:w-5/12">
                    본 사이트에서 제공하는 콘텐츠는 인터넷에서 수집한
                    자료입니다. 본 서버에 어떠한 자료도 저장하지 않습니다.
                    모든 콘텐츠의 저작권은 저작권자에 있으며, 이를 무단으로
                    이용하는 경우 법적 책임을 질 수 있습니다.
                </div>
                <div id="copyright">
                    Copyright © 2024 MEGAPORN All Right Reserved.
                </div>
            </div>
        </footer>
    );
}

export default Footer;
