import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Pagination = ({ pages }) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1100);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1100);
        };

        // 윈도우 크기가 변경될 때마다 handleResize 함수를 호출합니다.
        window.addEventListener("resize", handleResize);

        // 컴포넌트가 언마운트될 때 이벤트 리스너를 제거합니다.
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    if (pages.length === 0) return null;

    // 모바일용 페이지 배열을 준비합니다.
    const mobilePages =
        pages.length > 12 ? [...pages.slice(0, 8), ...pages.slice(-3)] : pages;

    // 사용할 페이지 배열을 결정합니다.
    const displayPages = isMobile ? mobilePages : pages;

    return (
        <div className="flex justify-center items-center space-x-1 md:space-x-2 pt-4 pb-10 md:pt-2 md:pb-40">
            {displayPages.map((page, index) =>
                page.href === "#" ? (
                    <p
                        key={index}
                        className="p-1 -mt-1 min-w-7 text-[#77777777] text-sm text-center"
                    >
                        ...
                    </p>
                ) : (
                    <Link
                        key={index}
                        to={page.href}
                        className={`p-1 min-w-7 text-white text-xs text-center rounded-md ${
                            page.active ? "bg-[#9347FF]" : "bg-[#77777777]"
                        } hover:bg-[#9347FF] hover:text-white transition-colors duration-200`}
                    >
                        {page.text}
                    </Link>
                )
            )}
        </div>
    );
};

export default Pagination;
