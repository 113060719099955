import React from "react";
import { Link } from "react-router-dom";

const banners = [
    {
        pc: "/banner/bettoday_lg.png",
        mobile: "/banner/bettoday.png",
        href: "",
    },
    {
        pc: "/banner/dafabet_lg.png",
        mobile: "/banner/dafabet.png",
        href: "https://dafabetofficial.com/kr/",
    },
];


const randomBanner = () => {
    const idx = Math.floor(Math.random() * banners.length);
    return banners[idx];
};

const HeaderAds = () => {
    const selectedBanner = randomBanner();

    return (
        <div className="w-full flex justify-center md:mb-2">
            <Link to={selectedBanner.href}>
                <div
                    className={`hidden md:flex max-w-screen-2xl relative`}
                >
                    <img src={selectedBanner.pc} alt="top Ad" />
                    <div className="absolute top-2 right-2 px-1 bg-gray-400 rounded-sm text-[10px]">
                        AD
                    </div>
                </div>
                <div className="flex md:hidden relative">
                    <img src={selectedBanner.mobile} alt="top Ad" />
                    <div className="absolute top-0.5 right-0.5 px-1 bg-gray-400 rounded-sm text-[10px]">
                        AD
                    </div>
                </div>
            </Link>
        </div>
    );
};

export default HeaderAds;
