import React from "react";

const PageInfo = ({ info }) => {
    return (
        <div className="flex px-2 md:px-0 items-end space-x-2 pt-1 md:pt-2 font-semibold">
            <span className="text-xl md:text-2xl">{info.title}</span>
            <span className="text-base md:text-lg">{info.total}</span>
        </div>
    );
};

export default PageInfo;
